/* src/index.css */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html, body {
    height: 100%;
    font-family: 'Arial', sans-serif;
    background-color: #ffffff;
  }
  
  body {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  