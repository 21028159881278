@import url('https://fonts.googleapis.com/css2?family=Bahiana&display=swap'); /* For Allan font */
@font-face {
  font-family: 'Hack Life';
  src: url('../assests/Fonts/Hacklife\ Regular.ttf') format('truetype'); /* Make sure the path is correct */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Hack Life';
  src: url('../assests/Fonts/Hacklife\ Regular.otf') format('truetype'); /* Bold version, if available */
  font-weight: bold;
  font-style: normal;
}

/* Apply the font to elements */
.heading-text, .center-text, .form-heading, .form-input, .form-submit {
  font-family: 'HackLife';
}

/* General container styling */
.home-container {
  width: 100vw;
  height: 100vh;
  background-color: black;
  position: relative;
  overflow: hidden;
}

/* Background glitch lines */
.home-container::before,
.home-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  mix-blend-mode: difference;
  animation: glitch-lines 1s infinite ease-in-out alternate;
  opacity: 0.6;
}

.home-container::before {
  animation-duration: 1s;
  opacity: 0.5;
}

.home-container::after {
  animation-duration: 0.75s;
  opacity: 0.4;
}

/* Glitch effect styling */
@keyframes glitch {
  0% { transform: translate(0, 0); }
  20% { transform: translate(-5px, -3px); }
  40% { transform: translate(5px, 3px); }
  60% { transform: translate(-3px, 2px); }
  80% { transform: translate(3px, -2px); }
  100% { transform: translate(0, 0); }
}

@keyframes glitch-lines {
  0%, 100% { clip-path: polygon(0 0, 100% 0, 100% 1%, 1 1.5%); } /* Slimmer glitch lines */
  10% { clip-path: polygon(0 8%, 100% 8%, 100% 8.5%, 0 8.5%); background-color: rgba(255, 0, 0, 0.3); }
  20% { clip-path: polygon(0 16%, 100% 16%, 100% 16.5%, 0 16.5%); background-color: rgba(0, 255, 0, 0.3); }
  30% { clip-path: polygon(0 24%, 100% 24%, 100% 24.5%, 0 24.5%); background-color: rgba(0, 0, 255, 0.3); }
  40% { clip-path: polygon(0 32%, 100% 32%, 100% 32.5%, 0 32.5%); background-color: rgba(255, 255, 0, 0.3); }
  50% { clip-path: polygon(0 40%, 100% 40%, 100% 40.5%, 0 40.5%); background-color: rgba(255, 0, 255, 0.3); }
  60% { clip-path: polygon(0 48%, 100% 48%, 100% 48.5%, 0 48.5%); background-color: rgba(0, 255, 255, 0.3); }
  70% { clip-path: polygon(0 56%, 100% 56%, 100% 56.5%, 0 56.5%); background-color: rgba(255, 165, 0, 0.3); }
  80% { clip-path: polygon(0 64%, 100% 64%, 100% 64.5%, 0 64.5%); background-color: rgba(128, 0, 128, 0.3); }
  90% { clip-path: polygon(0 72%, 100% 72%, 100% 72.5%, 0 72.5%); background-color: rgba(0, 255, 127, 0.3); }
}

/* Heading styling */
.heading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.heading-logo {
  width: 50px;
  height: 50px;
  margin-left: 15px;

}

.heading-text {
  font-size: 48px;
  font-family: 'Hack Life', sans-serif;
  font-weight: bold;
  
}

/* Centered content */
.center-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.center-image {
  max-width: 180px;
  width: 180px;
  height: auto;
  margin-bottom: 10px;
  mix-blend-mode: difference;
 
}

.center-text {
  font-size: 30px;
  font-family: 'bahiana', sans-serif;
  color: white;
  text-align: center;
  animation: glitch 1s infinite;
}

/* Corner logos */
.corner-logo {
  width: 50px;
  height: 50px;
  position: absolute;
}

.top-left { top: 20px; left: 20px; }
.top-right { top: 20px; right: 20px; }
.bottom-left { bottom: 20px; left: 20px; }
.bottom-right { bottom: 20px; right: 20px; }



/* Glitch Effect for Form Appearance */
.glitch-form {
  width: 591px;
  height: 501px;
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  padding: 40px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  animation: glitch-form 0.7s ease-in-out both;
}

@keyframes glitch-form {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.7);
  }
  50% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.1);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.form-heading {
  font-size: 32px;
  font-family: 'bahiana', sans-serif;
  color: black;
  margin-bottom: 20px;
  text-align: left;
}

.form-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-section {
  display: flex;
  gap: 20px;
}

.form-button {
  padding: 10px 20px;
  border: 1px solid black;
  border-radius: 12px;
  font-size: 28px;
  font-family: 'bahiana', sans-serif;
  color: #0D70E3;
  text-decoration: none;
  cursor: pointer;
  width: 250px;
}

.checkbox-plus {
  display: flex;
  align-items: center;
  gap: 150px;
}

.checkbox {
  width: 20px;
  height: 20px;
  display: none;
}

.plus-sign {
  width: 40px;
  height: 40px;
  margin-left:10px ;
  margin-right: 10px;
}

.form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid black;
  border-radius: 12px;
  font-size: 32px;
  color: rgba(0, 0, 0, 0.5);
  font-family: 'bahiana', sans-serif;
}

.form-submit {
  padding: 10px;
  background: black;
  border: 1px solid black;
  border-radius: 12px;
  font-size: 32px;
  font-family: 'bahiana', sans-serif;
  text-align: center;
  cursor: pointer;
  color: white;
}

.form-submit:hover {
  background-color: blueviolet;
}

/* Glitch effect for heading text */
.heading-text {
  position: relative;
  font-size: 48px;
  font-family: 'Hack Life', sans-serif;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  animation: glitch 2s infinite;
}

.heading-text::before, .heading-text::after {
  content: attr(data-text); /* Mirrors the main text */
  position: absolute;
  left: 0;
  top: 0;
  color: red; /* Red layer */
  opacity: 0.8;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0); /* Creates the glitch cutout effect */
  animation: glitch-before 2s infinite;
}

.heading-text::after {
  color: blue; /* Blue layer */
  z-index: -1;
  animation: glitch-after 2.5s infinite;
}

@keyframes glitch {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(-2px, 2px);
  }
  40% {
    transform: translate(3px, -3px);
  }
  60% {
    transform: translate(-2px, -1px);
  }
  80% {
    transform: translate(1px, 2px);
  }
  100% {
    transform: translate(0);
  }
}

@keyframes glitch-before {
  0% {
    clip: rect(0, 900px, 0, 0);
  }
  10% {
    clip: rect(8px, 900px, 14px, 0);
    transform: translate(2px, -2px);
  }
  20% {
    clip: rect(12px, 900px, 18px, 0);
    transform: translate(-2px, 2px);
  }
  30% {
    clip: rect(0px, 900px, 4px, 0);
    transform: translate(1px, 1px);
  }
  40% {
    clip: rect(4px, 900px, 10px, 0);
    transform: translate(0, -1px);
  }
  100% {
    clip: rect(0, 900px, 0, 0);
  }
}

@keyframes glitch-after {
  0% {
    clip: rect(0, 900px, 0, 0);
  }
  10% {
    clip: rect(10px, 900px, 16px, 0);
    transform: translate(-3px, 3px);
  }
  20% {
    clip: rect(14px, 900px, 20px, 0);
    transform: translate(3px, -3px);
  }
  30% {
    clip: rect(6px, 900px, 12px, 0);
    transform: translate(-1px, -1px);
  }
  40% {
    clip: rect(2px, 900px, 8px, 0);
    transform: translate(0, 2px);
  }
  100% {
    clip: rect(0, 900px, 0, 0);
  }
}
/* Glitch effect for center image */
.center-image {
  position: relative;
  width: 300px;
  height: auto;
  display: block;
  animation: glitch-image 2s infinite;
}

.center-image::before, .center-image::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-size: cover;
  mix-blend-mode: screen; /* Layer with different colors */
  opacity: 0.8;
}

.center-image::before {
  background-position: -5px -5px;
  animation: glitch-image-before 2s infinite;
}

.center-image::after {
  background-position: 5px 5px;
  animation: glitch-image-after 2.5s infinite;
  opacity: 0.6;
}

@keyframes glitch-image {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(-2px, 2px);
  }
  40% {
    transform: translate(3px, -3px);
  }
  60% {
    transform: translate(-2px, -1px);
  }
  80% {
    transform: translate(1px, 2px);
  }
  100% {
    transform: translate(0);
  }
}

@keyframes glitch-image-before {
  0% {
    clip: rect(0, 9999px, 0, 0);
  }
  10% {
    clip: rect(20px, 9999px, 150px, 0);
    transform: translate(2px, -2px);
  }
  30% {
    clip: rect(50px, 9999px, 200px, 0);
    transform: translate(-3px, 3px);
  }
  60% {
    clip: rect(30px, 9999px, 100px, 0);
    transform: translate(1px, 1px);
  }
  100% {
    clip: rect(0, 9999px, 0, 0);
  }
}

@keyframes glitch-image-after {
  0% {
    clip: rect(0, 9999px, 0, 0);
  }
  15% {
    clip: rect(30px, 9999px, 140px, 0);
    transform: translate(-3px, 3px);
  }
  40% {
    clip: rect(40px, 9999px, 160px, 0);
    transform: translate(3px, -3px);
  }
  70% {
    clip: rect(50px, 9999px, 120px, 0);
    transform: translate(-1px, -1px);
  }
  100% {
    clip: rect(0, 9999px, 0, 0);
  }
}
.form-button1{
  padding: 10px;
  background: white;
  border: 1px solid black;
  border-radius: 12px;
  font-size: 32px;
  font-family: 'bahiana', sans-serif;
  text-align: center;
  cursor: pointer;
  color: #0D70E3;
  text-decoration: none;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.confirmation-message {
  color: green;
  font-size: 16px;
  margin-top: 10px;
}
/* Adjust height for Safari's viewport */
@media screen and (max-width: 768px) {
  
}


@media (max-width: 480px) {
  /* Adjust home container size and layout for phones */
  .home-container {
    width: 100vw;
    height: 100vh;
    overflow-y: auto; /* Allow vertical scrolling */
    
  }
  .top-left { top: 40px; left: 20px; }
  .top-right { top: 40px; right: 20px; }
  /* Reduce glitch effect opacity for mobile view */
  .home-container::before,
  .home-container::after {
    opacity: 0.3;
  }

  /* Center heading container and adjust font size for smaller screens */
  .heading-container {
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
 
  }

  .heading-text {
    font-size: 28px; /* Smaller font size for mobile */
    margin-top: 60px;
  }

  .heading-logo {
    width: 30px;
    height: 30px;
    margin-top: 60px;
  }

  /* Center content adjustments */
  .center-content {
    top: 40%;
    left: 50%;
    transform: translate(-50%, -40%);
  }

  .center-image {
    width: 150px;
    height: auto;
  }

  .center-text {
    font-size: 16px; /* Smaller text for mobile */
    padding: 0 20px; /* Add padding for better readability */
  }

  /* Adjust corner logos */
  .corner-logo {
    width: 30px;
    height: 30px;
  }

  /* Glitch form adjustments */
  .glitch-form {
    width: 90%; /* Make form responsive */
    height: auto; /* Allow height to adjust based on content */
    padding: 10px;
  }

  .form-heading {
    font-size: 24px; /* Smaller heading for mobile */
  }

  .form-body {
    gap: 10px;
  }

  .form-section {
    flex-direction: row; /* Stack form sections vertically */
    gap: 10px;
  }

  .form-input {
    font-size: 20px; /* Adjust input font size */
  }

  .form-submit {
    font-size: 20px; /* Adjust button font size */
    padding: 10px;
  }

  .form-button {
    font-size: 20px; /* Adjust button font size */
    width: 150px;
  }
  .form-button1 {
    font-size: 20px; /* Adjust button font size */
   
  }

  /* Adjust checkbox layout */
  .checkbox-plus {
    gap: 50px;
    flex-direction: column; /* Stack elements vertically on smaller screens */
  }

  .plus-sign {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
   
  }
}
